import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputNumber from '../../AddInterventionMenu/InputNumber';

const Period = ({period, onPeriodChange}) => {
  return <Box mt={2} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
    <Typography>Duración del análisis</Typography>
    <InputNumber max={1000} min={0}
      value={period} onChange={(value) => onPeriodChange(value)}
    />
  </Box>;
};

Period.propTypes = {
  period: PropTypes.number.isRequired,
  onPeriodChange: PropTypes.func.isRequired,
};

export default Period;
import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import ReadOnlyData from './ReadOnlyData';
import EditableData from './EditableData';

const MassDescription = ({idRodal, specieLabel, age, area, numberPerHa, dominantHeight, basimetricArea, siteIndex, onMassChange}) => {
  return <Box  sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 4}}>
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
      <ReadOnlyData label='ID Rodal' value={idRodal}/>
      <ReadOnlyData label='Especie' value={specieLabel.toUpperCase()}/>
      <ReadOnlyData label='Edad' value={age ? `${age} años` : '-'}/>
      <ReadOnlyData label='Superficie' value={`${area} Ha`}/>
    </Box>
    <Box sx={{display: 'grid', gridTemplateRows: 'repeat(2, 1fr)', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2, gridTemplateAreas: '"tree height" "area index"'}}>
      <Box sx={{gridArea: 'tree'}}>
        <EditableData onChange={(value) => onMassChange({numberPerHa: value})} label='Nº de árboles por hectárea*' value={numberPerHa}/>
      </Box>
      <Box sx={{gridArea: 'height'}}>
        <EditableData onChange={(value) => onMassChange({dominantHeight: value})} label='Altura dominante*' value={dominantHeight}/>
      </Box>
      <Box sx={{gridArea: 'area'}}>
        <EditableData onChange={(value) => onMassChange({basimetricArea: value === 0 ? undefined : value})} label='Área basimétrica' value={basimetricArea}/>
      </Box>
      <Box sx={{gridArea: 'index'}}>
        <EditableData onChange={(value) => onMassChange({siteIndex: value === 0 ? undefined : value})} label='Índice de sitio' value={siteIndex}/>
      </Box>
    </Box>
  </Box>;
};

MassDescription.propTypes = {
  idRodal: PropTypes.number.isRequired,
  specieLabel: PropTypes.string.isRequired,
  age: PropTypes.number,
  area: PropTypes.number.isRequired,
  numberPerHa: PropTypes.number,
  dominantHeight: PropTypes.number,
  basimetricArea: PropTypes.number,
  siteIndex: PropTypes.number,
  onMassChange: PropTypes.func.isRequired
};

export default MassDescription;
import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Hidden from '@mui/material/Hidden';
import {styled} from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//BIKENTA
import DropDown from '../../DropDown';
import ExistencesCard from './ExistencesCard';
import MaderaPlusResults from '../MaderaPlus/MaderaPlusResults';
import MaderaPluspProspectionPointsCalculation from '../MaderaPlus/MaderaPluspProspectionPointsCalculation';
import MenuHeader from './MenuHeader';
import MenuData from './MenuData';
import MenuExistences from './MenuExistences';
import MenuInterventionsList from './MenuInterventionsList';
import DialogPanel from '../../DialogPanel';

//UTILS
import {ROLES_LABEL, SPECIES_INCLUDED_BIOMASCO2} from '../../../config';
import {prospectingPoints} from '../../proptypes/prospectingPoints';
import {intervention} from '../../proptypes/intervention';
import {elastic_module} from '../../proptypes/elastic_module';
import {annualPlan} from '../../proptypes/annualPlan';
import BiomasCO2Menu from './BiomasCO2/BiomasCO2Menu';

//STYLES
const TabContent = styled(TabPanel)({
  padding: '8px 0 0 4px',
  marginTop: 0
});
const layerTab = {
  minWidth: 60,
  width: 'auto',
  margin: '0px 12px 0px 0px',
  bottom: -5,
  p: 0
};
const tabGroup = {
  '&.MuiTabs-root': {
    m: '0 0 8px 0',
  },
  '& .MuiButtonBase-root': {
    mx: 1,
  }
};
const tabIntervention = {
  p: 0,
  display: 'flex',
  flexDirection: 'column',
  height: '36vh',
};

const InterventionZoneMenu = ({
  id,
  uo,
  ua,
  cuartel,
  refcat,
  stratum,
  area,
  age,
  elastic_module,
  specie,
  species,
  interventions,
  isOffline,
  isMaderaPlusMode,
  maderaPlusAvailableSpecies,
  role,
  selectedAnnualPlan,
  prospectingPoints,
  onBiomasCO2Open,
  onContactRSC,
  onExportClick,
  onMaderaPlusFormOpen,
  onMaderaPlusEnd,
  onNotificationClick,
  onShareClick,
  onInterventionAdd,
  onInterventionSelect,
}) => {
  const handleExportClick = () => onExportClick();
  const handleNotificationClick = () => onNotificationClick();
  const handleShareClick = () => onShareClick();
  const [tabValue, setTabValue] = useState('data');
  const handleTabChange = (event, newValue) => setTabValue(newValue);
  const handleSelectIntervention = (interventionId) => onInterventionSelect && onInterventionSelect(interventionId);
  const handleMaderaPlusFormOpen = () => onMaderaPlusFormOpen();

  const [modalRSC, setModalRSC] = useState(false);

  const headerInterventionZoneMenu = () => <MenuHeader
    uo={uo}
    ua={ua}
    cuartel={cuartel}
    onNotificationClick={handleNotificationClick}
    onExportClick={handleExportClick}
    onShareClick={handleShareClick}
  />;

  const DataPanel = () => <MenuData age={age} area={area} refcat={refcat} stratum={stratum}/>;
  const RSCButton = () => <Button variant='contained' color='secondary' startIcon={<EmailIcon />} sx={{mt: 2, minWidth: '150px'}} onClick={() => setModalRSC(true)}>CONTACTAR CON RSC</Button>;

  const InterventionList = () => <MenuInterventionsList
    interventions={interventions}
    isOffline={isOffline}
    role={role}
    selectedAnnualPlan={selectedAnnualPlan}
    onInterventionSelect={(interventionId) => handleSelectIntervention(interventionId)}
    onInterventionAdd={onInterventionAdd}
  />;

  const MaderaPlusResultsPanel = () => <MaderaPlusResults
    maderaPlusAvailableSpecies={maderaPlusAvailableSpecies}
    onMaderaPlusFormOpen={handleMaderaPlusFormOpen}
    elastic_module={elastic_module}
  />;

  const StockPanels = () => <MenuExistences species={species}/>;

  const handleContact = () => {
    setModalRSC(false);
    onContactRSC(id);
  };

  // eslint-disable-next-line react/prop-types
  const speciesIdBiomasCO2 = SPECIES_INCLUDED_BIOMASCO2.map(specie => specie.bbddId);

  // eslint-disable-next-line react/prop-types
  const hasBiomasMenu = species.length === 1 && speciesIdBiomasCO2.includes(species[0].id);

  return <div id='intervention-zone-detail'>
    <Hidden lgUp>{/*MOBILE*/}
      <Stack sx={{position: 'relative', height: '100%'}}>
        {headerInterventionZoneMenu()}
        <TabContext value={tabValue} sx={{position: 'relative', height: '100%'}}>
          <Tabs
            value={tabValue}
            indicatorColor='secondary'
            textColor='secondary'
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons='auto'
            sx={tabGroup}
          >
            <Tab label='DATOS' value='data' sx={layerTab}/>
            <Tab label='EXISTENCIAS' value='stock' sx={layerTab}/>
            <Tab label='MÓDULO MADERA+' value='maderaPlus' sx={layerTab}/>
            <Tab label='INTERVENCIONES' value='intervention' sx={layerTab}/>
          </Tabs>
          <TabContent value='data'>
            <DataPanel/>
            <RSCButton/>
          </TabContent>
          <TabContent value='stock'>
            <StockPanels/>
          </TabContent>
          <TabPanel value='maderaPlus' sx={{p: 0, m:0}}>
            {
              !isMaderaPlusMode && <MaderaPlusResultsPanel/>
            }
            {
              isMaderaPlusMode && <MaderaPluspProspectionPointsCalculation
                prospectingPoints={prospectingPoints}
                specie={specie}
                onMaderaPlusEnd={onMaderaPlusEnd}
              />
            }
          </TabPanel>
          <TabPanel sx={tabIntervention} value='intervention'>
            <InterventionList/>
          </TabPanel>
        </TabContext>
      </Stack>
    </Hidden>
    <Hidden lgDown>{/*DESKTOP*/}
      {headerInterventionZoneMenu()}
      <DataPanel/>
      <RSCButton/>
      <DropDown title='EXISTENCIAS' hasData={!!species?.length}>
        <StockPanels/>
      </DropDown>
      <DropDown title='INTERVENCIONES' id='intervention-zone-detail-intervenciones' hasData={!!interventions?.length}>
        <InterventionList/>
      </DropDown>
      {hasBiomasMenu &&
        <DropDown title='BIOMAS CO2' hasData={true}>
          <BiomasCO2Menu isOffline={isOffline} onBiomasCO2Open={onBiomasCO2Open}/>
        </DropDown>
      }
      <DropDown title='MÓDULO MADERA+' hasData={!!species?.length}>
        <MaderaPlusResultsPanel/>
      </DropDown>
    </Hidden>
    {
      modalRSC &&
      <DialogPanel onClose={() => setModalRSC(false)} isOpen={modalRSC} label='CONTACTAR CON RSC' fullWidth={true}>
        <Typography gutterBottom>¿Estás seguro de querer contactar con RSC?</Typography>
        <Typography gutterBottom>Se enviará un mail manifestando tu interés en la Unidad de Actuación {ua}</Typography>
        <Box display='flex' flexDirection='row' justifyContent='flex-end' mt={1} mr={1.2} sx={{gap: 2}}>
          <Button color="secondary" onClick={() => setModalRSC(false)}>CANCELAR</Button>
          <Button color="secondary" variant='contained' onClick={handleContact}>ACEPTAR</Button>
        </Box>
      </DialogPanel>
    }
  </div>;
};

export default InterventionZoneMenu;

InterventionZoneMenu.propTypes = {
  area: PropTypes.number.isRequired,
  age: PropTypes.number,
  uo: PropTypes.string,
  ua: PropTypes.string,
  cuartel: PropTypes.string,
  elastic_module: PropTypes.arrayOf(elastic_module),
  id: PropTypes.number.isRequired,
  interventions: PropTypes.arrayOf(intervention),
  isMaderaPlusMode: PropTypes.bool,
  isOffline: PropTypes.bool,
  maderaPlusAvailableSpecies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    existences: PropTypes.shape({
      age: PropTypes.number,
      treeFeet: PropTypes.number,
      height: PropTypes.number,
      basometricArea: PropTypes.number,
      volumeWithBark: PropTypes.number,
      volumeWithoutBark: PropTypes.number,
      biomass: PropTypes.number,
    })
  })),
  prospectingPoints: prospectingPoints,
  refcat: PropTypes.string,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  selectedAnnualPlan: annualPlan,
  stratum: PropTypes.string,
  specie: PropTypes.shape({
    ...ExistencesCard.propTypes
  }),
  species: PropTypes.arrayOf(PropTypes.shape({
    ...ExistencesCard.propTypes
  })).isRequired,
  onBiomasCO2Open: PropTypes.func.isRequired,
  onContactRSC: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
  onMaderaPlusFormOpen: PropTypes.func,
  onMaderaPlusEnd: PropTypes.func,
  onNotificationClick: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired,
  onInterventionAdd: PropTypes.func.isRequired,
  onInterventionSelect: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const EditableData = ({label, value, onChange, isRequired=false}) => {
  return <Box mt={0.5} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1}}>
    <Typography sx={{minWidth: '90px'}}>{label}<span style={{color: 'red'}}>{isRequired ? '*' : ''}</span></Typography>
    <TextField
      error={isRequired && value.length === 0}
      inputProps={{ type: 'number'}}
      variant='outlined'
      size='small'
      value={value}
      helperText={isRequired && value.length === 0 ? 'Campo obligatorio' : ''}
      onChange={(e) => onChange(Number(e.target.value))}
    />
  </Box>;
};

EditableData.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default EditableData;
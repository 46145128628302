import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MassDescription from './MassDescription';
import BiomasSection from './BiomasSection';
import Period from './Period';
import TargetProduct from './TargetProduct';
import {SPECIES_INCLUDED_BIOMASCO2} from '../../../../config';


//BIKENTA

//UTILS

//STYLES
const containerSx = {
  display: 'grid',
  gridTemplateAreas: `
  'period period' 'massDescription massDescription' 'targetProduct targetProduct' 'baseLine forestryProject' 'absorptionPlan absorptionPlan' '. close' `,
  //gridTemplateRows: 'repeat(5, 1fr)',
  gridTemplateColumns: 'repeat(2, 1fr)',
  margin: '10px',
  width: '100%'
};

const BiomasCO2Form = ({selectedInterventionZone, onBiomasCO2Close}) => {

  const initialBiomasParams = {
    rodalId: selectedInterventionZone.rodal_id,
    initialYear: 15, //qué es
    type: 'BASELINE',
    timeFrame: 15,
    speciesId: SPECIES_INCLUDED_BIOMASCO2.filter(specie => specie.bbddId === selectedInterventionZone.species[0].id).xorforId,
    initialAge: selectedInterventionZone.species[0].existences.age,
    dominantHeight: selectedInterventionZone.species[0].existences.height,
    basimetricArea: selectedInterventionZone.species[0].existences.basimetricArea,
    numberPerHa: selectedInterventionZone.species[0].existences.treeFeet,
    area: selectedInterventionZone.area,
    comercialPurpose: 'PAPER'
  };
  
  const [biomasParams, setBiomasParams] = useState(initialBiomasParams);
  
  const {id, species} = selectedInterventionZone;
  console.log('biomasParams', biomasParams);
  return <Box sx={containerSx}>
    <BiomasSection number={1} label='duración del proyecto' sx={{gridArea: 'period'}}>
      <Period period={biomasParams.initialYear} onPeriodChange={() => {}}/>
    </BiomasSection>
    <BiomasSection number={2} label='descripción de la masa' sx={{gridArea: 'massDescription'}}>
      <MassDescription area={biomasParams.area} specieLabel={species[0].label} idRodal={id} age={biomasParams.initialAge}
        numberPerHa={biomasParams.numberPerHa} dominantHeight={biomasParams.dominantHeight}
        basimetricArea={biomasParams.basimetricArea}
        onMassChange={(value) => setBiomasParams({...biomasParams, ...value})} />
    </BiomasSection>
    <BiomasSection number={3} label='producto objetivo' sx={{gridArea: 'targetProduct'}}>
      <TargetProduct selectedProductId={biomasParams.comercialPurpose} onProductChange={(value) => setBiomasParams({...biomasParams, comercialPurpose: value})}/>
    </BiomasSection>
    <BiomasSection number={4} label='línea base' sx={{gridArea: 'baseLine'}}><></></BiomasSection>
    <BiomasSection number={5} label='proyecto silvícola' sx={{gridArea: 'forestryProject'}}><></></BiomasSection>
    <BiomasSection number={6} label='plan de absorción' sx={{gridArea: 'absorptionPlan'}}><></></BiomasSection>

    <Button variant='contained' color='secondary' sx={{gridArea: 'close', mr: 2, mt: 2, width: '250px', margin: 'auto'}} onClick={onBiomasCO2Close}>SALIR</Button>
  </Box>;
};

export default BiomasCO2Form;

BiomasCO2Form.propTypes = {
  selectedInterventionZone: PropTypes.object,
  onBiomasCO2Close: PropTypes.func.isRequired
};
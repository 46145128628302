import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'regenerator-runtime/runtime.js';

import {useAsyncFn, useTimeoutFn} from 'react-use';
import {getUserNotifications, readUserNotification} from '../services/fileNotifications';
import {useHistory, useRouteMatch} from 'react-router-dom';
import DeleteFiltersDialog from '../components/DeleteFiltersDialog';

import {
  apiPostDeleteBatch,
  apiGetBatches,
  apiGetCommunityFiles,
  apiPostCreateBatch,
  apiPostUpdateBatch,
  apiResetBatches,
  apiResetCommunityFiles,
  apiCleanInterventionZoneDetail,
  apiDeleteCommoner,
  apiDeleteExecution,
  apiDeleteFile,
  apiDeleteIntervention,
  apiDownloadFile,
  apiDownloadInterventionZoneGeometry,
  apiCreateCommoner,
  apiGetAnnualPlans,
  apiGetCommoners,
  apiGetCommunities,
  apiGetInterventionZoneDetail,
  apiGetInterventionZones,
  apiLogout,
  apiPostCompleteAnnualPlan,
  apiPostCreateAnnualPlan,
  apiCreateIntervention,
  apiPostInProcedureAnnualPlan,
  apiResetCommoners,
  apiUpdateCommoner,
  apiUpdateInterventionAnnualPlan,
  apiResetAnnualPlans,
  apiResetInterventionZones,
  apiUploadFile,
  apiDeleteAnnualPlan,
  apiInviteCommoner,
  apiGetCommunityDetails,
  apiDeleteCommunityFile,
  apiUploadCommunityFile,
  apiUpdateIntervention,
  apiGetBusiness,
  apiActivateCommoner,
  apiDeactivateCommoner,
  apiGetBusinessWithLocation,
  apiMaderaPlusGetSpecies,
  apiMaderaPlusGetDistrict,
  apiGetAccountEntries,
  apiResetAccountEntries,
  apiDeleteAccountEntries,
  apiCreateAccountEntry,
  apiUpdateAccountEntry,
  apiDownloadAnnualPlan,
  apiGetRolesList,
  apiGetFileDetail,
  apiPostMultipleExecution,
  apiPostUpdateMultipleInterventions,
  apiPostNeverDo,
  apiPostCreateExecution,
  apiUpdateExecution,
  apiPostCreateMultipleInterventions, apiCreatePlantation, apiSendToRSC,
} from '../modules/api/actions';
import {
  getInterventionZoneDetail,
  getProfile,
  getBusiness,
  getBusinessWithLocation,
  getToken, getSpecies
} from '../modules/api/selectors';
import CommunityMap from '../components/CommunityMap/CommunityMap';
import {
  activeCategoryFilters,
  activeFilters,
  moveMap,
  resetSelectedAnnualPlan,
  selectAnnualPlan,
  selectCommunity,
  selectCommunityOnCarousel,
  selectIntervention,
  selectInterventionZone,
  selectInterventionZoneOnCarousel,
  setBaseMap,
  updateSearchedText
} from '../actions';
import {getInterventionZonesIdsFromAnnualPlan} from '../selectors';
import {
  activedCategoryFiltersValues,
  activedFilters as activedFiltersSelector,
  getAnnualPlans,
  getAnnualPlansFromSelectedIntervention,
  getAnnualPlansFromSelectedInterventionZones,
  getAnnualPlanSummary,
  getBoardMembers,
  getCategories,
  getCategoriesAndInterventionZonesIdsOfInterventionsFromAnnualPlan,
  getCommunities,
  getCommunitiesInState,
  getFiltersCategoriesDescriptions,
  getFiltersDescriptions,
  getInterventionsFromSelectedCommunity,
  getInterventionTypes,
  getMaderaPlusSpecies,
  getMaderaPlusDistrict,
  getRenderInterventionZones,
  selectedCommunity as selectedCommunitySelector,
  selectedIntervention as selectedInterventionSelector,
  selectedInterventionZone as selectedInterventionZoneSelector,
  selectedInterventionZones as selectedInterventionZonesSelector,
  getInterventionZonesAndItsInterventionsAllFiltered,
  getSelectedInterventionZoneCentroid,
  getBatches,
  getCommunityFiles,
} from '../selectors';
import AdvancedFiltersView from './AdvancedFiltersView';
import {ROLES_LABEL} from '../config';

const CommunityMapPage = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [isAdvancedFiltersViewOpen, setIsAdvancedFiltersViewOpen] = useState(false);
  const [isDeleteFilterDialogOpen, setIsDeleteFilterDialogOpen] = useState(undefined);


  //NOTIFICATIONS
  const token = useSelector(getToken);
  const prevNotificationsCount = useRef(0);
  const [{value: notifications}, getNotifications] = useAsyncFn(async () => {
    reset();
    return getUserNotifications(token);
  }, [], {value: []});
  const [, , reset] = useTimeoutFn(getNotifications, 10000);

  useEffect(() => {
    if (prevNotificationsCount.current !== notifications.length) {
      dispatch(apiGetCommunityFiles(parseInt(communityId)));
    }
    prevNotificationsCount.current = notifications.length;
  }, [notifications]);
  
  //SELECTORS
  const activedCategoryFilters = useSelector(activedCategoryFiltersValues);
  const activedFilters = useSelector(activedFiltersSelector);
  const accountEntries = useSelector((state) => state.api.accountEntries);
  const annualPlans = useSelector(getAnnualPlans);
  const annualPlanSummary = useSelector(getAnnualPlanSummary);
  const annualPlansAssignedInSelectedIntervention = useSelector(getAnnualPlansFromSelectedIntervention);
  const annualPlansAssignedInSelectedInterventionZones = useSelector(getAnnualPlansFromSelectedInterventionZones);
  const annualPlanInterventionZoneIdsByCategoryGroup = useSelector(getCategoriesAndInterventionZonesIdsOfInterventionsFromAnnualPlan);
  const basemap = useSelector((state) => state.app.map.basemap);
  const batches = useSelector(getBatches);
  const communityFiles = useSelector(getCommunityFiles);
  const board_members = useSelector(getBoardMembers);
  const business = useSelector(getBusiness);
  const businessWithLocation = useSelector(getBusinessWithLocation);
  const carouselCommunityId = useSelector((state) => state.app.carouselCommunityId);
  const carouselInterventionZoneId = useSelector((state) => state.app.carouselInterventionZoneId);
  const categories = useSelector(getCategories);
  const categoryFilters = useSelector(getFiltersCategoriesDescriptions);
  const selectedInterventionZoneCentroid = useSelector(getSelectedInterventionZoneCentroid);
  const commoners = useSelector((state) => state.api.commoners);
  const communities = useSelector(getCommunities);
  const communitiesInState = useSelector(getCommunitiesInState);
  const filters = useSelector(getFiltersDescriptions);
  const history = useHistory();
  const interventions = useSelector(getInterventionsFromSelectedCommunity);
  const interventionTypes = useSelector(getInterventionTypes);
  const interventionZoneDetail = useSelector(getInterventionZoneDetail);
  const interventionZones = useSelector(getRenderInterventionZones);
  const interventionZoneIdsFromAnnualPlan = useSelector(getInterventionZonesIdsFromAnnualPlan);
  const interventionZonesForDashboard = useSelector(getInterventionZonesAndItsInterventionsAllFiltered);
  const isLoading = useSelector((state) => !!state.app.loading);
  const isOffline = useSelector((state) => state.app.offline);
  const maderaPlusSpecies = useSelector(getMaderaPlusSpecies);
  const maderaPlusDistrict = useSelector(getMaderaPlusDistrict);
  const profile = useSelector(getProfile);
  const fileDetail = useSelector((state) => state.app.fileDetail);
  const role = profile.roles[0];
  const rolesList = useSelector((state) => state.api.rolesList);
  const searchedText = useSelector((state) => state.app.searchedText);
  const selectedAnnualPlan = useSelector((state) => state.app.selectedAnnualPlan);
  const selectedCommunity = useSelector(selectedCommunitySelector);
  const selectedIntervention = useSelector(selectedInterventionSelector);
  const selectedInterventionZone = useSelector(selectedInterventionZoneSelector);
  const selectedinterventionZones = useSelector(selectedInterventionZonesSelector);
  const species = useSelector(getSpecies);
  const viewport = useSelector((state) => state.app.map.viewport);
  const mapProps = {
    activedFilters,
    activeFilters: (filters) => dispatch(activeFilters(filters)),
    annualPlanInterventionZoneIdsByCategoryGroup,
    annualPlans,
    annualPlansAssignedInSelectedIntervention,
    annualPlansAssignedInSelectedInterventionZones,
    annualPlanSummary,
    basemap,
    batches,
    businessWithLocation,
    carouselCommunityId,
    carouselInterventionZoneId,
    categories,
    categoryFilters,
    commoners,
    communities,
    communityFiles,
    fileDetail,
    filters,
    maderaPlusSpecies,
    maderaPlusDistrict,
    notifications,
    isLoading,
    isOffline,
    interventions,
    interventionTypes,
    interventionZoneDetail,
    interventionZones,
    interventionZoneIdsFromAnnualPlan,
    interventionZonesForDashboard,
    openAdvanceFilters: () => setIsAdvancedFiltersViewOpen(true),
    role,
    rolesList,
    profile,
    searchedText,
    selectCommunityOnCarousel: (id) => dispatch(selectCommunityOnCarousel(id)),
    selectedAnnualPlan,
    selectedCommunity,
    selectedIntervention,
    selectedInterventionZone,
    selectedinterventionZones,
    selectInterventionZoneOnCarousel: (id) => dispatch(selectInterventionZoneOnCarousel(id)),
    species,
    viewport,

    //ANNUAL PLAN-------------------------------------------------------------------------------------------------------
    onAnnualPlanDownload: (selectedAnnualPlanId) => dispatch(apiDownloadAnnualPlan(selectedAnnualPlanId)),
    onAnnualPlanClick: (annualPlan) => dispatch(selectAnnualPlan(annualPlan)),
    onAnnualPlanComplete: (annualPlanId) => dispatch(apiPostCompleteAnnualPlan(annualPlanId)),
    onAnnualPlanCreate: (year) => {
      dispatch(apiPostCreateAnnualPlan({
        id: selectedCommunity.id,
        year
      }));
    },
    onAnnualPlanDelete: (annualPlanId) => dispatch(apiDeleteAnnualPlan(annualPlanId)),
    onAnnualPlanInProcedure: (annualPlanId) => dispatch(apiPostInProcedureAnnualPlan(annualPlanId)),
    onAnnualPlanSave: () => dispatch(resetSelectedAnnualPlan()),
    onAnnualPlanUpdateIntervention: (interventions) => {
      dispatch(apiUpdateInterventionAnnualPlan({
        id: selectedAnnualPlan.id,
        interventions: [interventions]
      }));
    },

    onBaseMapChange: (basemap) => dispatch(setBaseMap(basemap)),
    onCommunityFileDelete: (fileId) => dispatch(apiDeleteCommunityFile({
      selectedCommunityId: selectedCommunity.id,
      fileId
    })),
    onCommunityFileDownload: (fileId, content_type) => dispatch(apiDownloadFile({fileId, content_type})),

    onCommunitySelect: (id) => dispatch(apiGetCommunityDetails(id)),

    //RSC---------------------------------------------------------------------------------------------------------------
    onContactRSC: (id) => dispatch(apiSendToRSC(id)),

    //INTERVENTIONS-----------------------------------------------------------------------------------------------------
    onCreateIntervention: (intervention) => dispatch(apiCreateIntervention({
      interventionZoneId: selectedInterventionZone.id,
      intervention
    })),
    onCreatePlantation: (intervention, specieId) => dispatch(apiCreatePlantation({
      interventionZoneId: selectedInterventionZone.id,
      intervention,
      specieId
    })),
    onDeleteIntervention: (interventionZoneId, interventionId) => dispatch(apiDeleteIntervention({
      interventionZoneId,
      interventionId
    })),
    onUpdateIntervention: (id, intervention) => dispatch(apiUpdateIntervention({id, intervention})),
    onUpdatePlantation: (id, intervention, specieId) => dispatch(apiUpdateIntervention({id, intervention, specieId})),
    onInputInterventionZoneGeometryStarts: (interventionZoneId) => dispatch(apiGetInterventionZoneDetail(interventionZoneId)),
    onInputInterventionZoneGeometryEnds: () => dispatch(apiCleanInterventionZoneDetail()),
    onExportFilterResult: (selectedCommunityId, interventionZoneIds) => dispatch(apiDownloadInterventionZoneGeometry({
      selectedCommunityId,
      interventionZoneIds
    })),
    onNeverDo: (interventionId) => dispatch(apiPostNeverDo(interventionId)),

    //EXECUTIONS--------------------------------------------------------------------------------------------------------
    onCreateExecution: execution => dispatch(apiPostCreateExecution({execution, interventionId: selectedIntervention.id})),
    onUpdateExecution: execution => dispatch(apiUpdateExecution(execution)),
    onDeleteExecution: executionId => {
      dispatch(apiDeleteExecution({
        executionId,
        interventionZoneId: parseInt(interventionZoneId),
        interventionId: parseInt(interventionId),
      }));
      // dispatch(apiGetIntervention(parseInt(interventionId)));
    },

    //BATCHES-----------------------------------------------------------------------------------------------------------
    onCreateBatch: (name) => dispatch(apiPostCreateBatch({
      communityId: selectedCommunity.id,
      batch: {
        'name': name,
        'executions': []
      }
    })),
    onEditBatch: (batch) => dispatch(apiPostUpdateBatch(batch)),
    onDeleteBatch: (batchId) => dispatch(apiPostDeleteBatch(batchId)),

    //FILES-------------------------------------------------------------------------------------------------------------
    onFileDetailOpen: (fileId) => dispatch(apiGetFileDetail(fileId)),
    onDeleteFile: (interventionZoneId, interventionId, fileId) => dispatch(apiDeleteFile({
      interventionZoneId,
      interventionId,
      fileId
    })),
    onDownloadFile: (fileId, content_type) => dispatch(apiDownloadFile({fileId, content_type})),
    onCommunityFileUpload: (selectedCommunityId, uploadFormData) => dispatch(apiUploadCommunityFile({
      selectedCommunityId: selectedCommunity.id,
      uploadFormData
    })),
    onLogout: () => dispatch(apiLogout()),
    onMaderaPlusGetDistrict: () => dispatch(apiMaderaPlusGetDistrict(selectedInterventionZoneCentroid)),
    /*onMaderaPlusGetPropspectingPoints: () => dispatch(apiMaderaPlusGetDistrict(selectedInterventionZoneCentroid)),*/

    //MULTIPLE----------------------------------------------------------------------------------------------------------
    onCreateMultipleInterventions: (interventionZonesToAddIntervention, newIntervention) =>
      dispatch(apiPostCreateMultipleInterventions({
        communityId,
        intervention_zones: interventionZonesToAddIntervention,
        intervention: newIntervention
      })),
    onCreateMultiplePlantations: (interventionZonesToAddIntervention, newIntervention, specieId) =>
      dispatch(apiPostCreateMultipleInterventions({
        communityId,
        intervention_zones: interventionZonesToAddIntervention,
        intervention: newIntervention,
        species: specieId
      })),
    onNeverDoMultipleIntervention: intervention => dispatch(apiPostUpdateMultipleInterventions({
      communityId,
      intervention
    })),
    onMultipleExecutionUpdate: execution => dispatch(apiPostMultipleExecution({
      communityId,
      execution
    })),


    onSearchClick: (text) => dispatch(updateSearchedText(text)),
    onUploadFile: (interventionZoneId, interventionId, uploadFormData) => dispatch(apiUploadFile({
      interventionZoneId,
      interventionId,
      uploadFormData
    })),
    onViewportChange: (vp) => dispatch(moveMap(vp)),
    readUserNotification: (notification_id) => {
      readUserNotification(notification_id, token)
        .then(getNotifications);
    },
    commonersListViewProps: {
      board_members,
      commoners,
      selectedCommunity,
      onCommonerAdd: () => dispatch(apiCreateCommoner(communityId)),
      onCommonerActivate: (id) => dispatch(apiActivateCommoner(id)),
      onCommonerDeactivate: (id) => dispatch(apiDeactivateCommoner(id)),
      onCommonerDelete: (id) => dispatch(apiDeleteCommoner(id)),
      onCommonerInvite: (id) => dispatch(apiInviteCommoner(id)),
      onCommonerUpdate: (commoner) => dispatch(apiUpdateCommoner(commoner)),
      role
    },
    businessListViewProps: {
      business
    },
    accountBookViewProps: {
      accountEntries,
      onAccountEntryAdd: (entry) => dispatch(apiCreateAccountEntry({entry, communityId})),
      onAccountEntryDelete: (id) => dispatch(apiDeleteAccountEntries(id)),
      onAccountEntryUpdate: (entry) => dispatch(apiUpdateAccountEntry(entry)),
    }
  };

  const advancedFilterViewProps = {
    isAdvanceFilterOpen: isAdvancedFiltersViewOpen,
    categoryFilters,
    selectedCategoryFilters: activedCategoryFilters,
    onClose: () => setIsAdvancedFiltersViewOpen(false),
    onSaveFilter: (filters) => dispatch(activeCategoryFilters(filters)) && setIsAdvancedFiltersViewOpen(false)
  };
  
  const {communityId} = match.params;
  const {interventionZoneId} = match.params;
  const {interventionId} = match.params;
  useEffect(() => {
    if (!communitiesInState.length && !searchedText) {
      dispatch(apiGetCommunities());
      dispatch(apiGetRolesList());
      dispatch(apiGetBusiness());
      dispatch(apiGetBusinessWithLocation());
      dispatch(apiMaderaPlusGetSpecies());
      //dispatch(apiMaderaPlusGetData({x: 511170.49549543305, y: 4678411.913268634,  specieId: 26, district: 19, }));
      /*FUNCIONA--dispatch(apiPostUpdateElasticModule({
        interventionZoneId: 1483,
        id: 26,
        name: 'Pinus pinaster',
        t: 30.0,
        n: 247.0,
        g: 323.9,
        dc: 32.0,
        do: 45.8,
        hm: 23.9,
        hc: 23.7,
        ho: 15.4,
        esb: 34.7,
        cr: 34.7,
        vcc: 78.8,
        ih: 8.8,
        db_p: 23.4,
        moe_est: 25.0,
        moe_d: 34.5,
        hmt: 23.1,
        hmtab: 22.0,
        rend: 33.3,
        ce_superior: 23.5,
        porc_estr: 11.3,
        hum: 99.9,
        altitud: 44.4,
        pendiente: 34.6,
        orientacion: 23.0,
        tm: 43.7,
        pm: 77.9,
      }));*/
      /*FUNCIONA--dispatch(apiGetProspectingPoints({pointId: 1}));*/
      /*dispatch(apiDeleteProspectingPoints({pointId: 14}));*/
      /*dispatch(apiPostProspectingPoints({
        interventionZoneId: 1483,
        species: 26,
        points: [
          [42.259, -8.869]
        ]
      }));*/
    } else if (communityId && !communitiesInState.filter(({id}) => id === parseInt(communityId)).length){
      setIsDeleteFilterDialogOpen('La comunidad seleccionada no existe, por favor, vuelva a intentarlo.');
      history.push('/');
    }
    else if (
      communityId &&
      communitiesInState.filter(({id}) => id === parseInt(communityId)).length &&
      !communities.filter(({id}) => id === parseInt(communityId)).length &&
      activedFilters.length
    ) {
      setIsDeleteFilterDialogOpen('Se han borrado los filtros para poder mostrar la comunidad seleccionada.');
      dispatch(activeFilters([]));
    } else if (communityId && communityId !== `${selectedCommunity?.id}`) {
      dispatch(selectCommunity(communities.find(community => community.id === parseInt(communityId))));
      dispatch(apiGetInterventionZones(parseInt(communityId)));
      dispatch(apiGetAnnualPlans(parseInt(communityId)));
      dispatch(apiGetCommoners(parseInt(communityId)));
      dispatch(apiGetCommunityFiles(parseInt(communityId)));
      if(role === ROLES_LABEL.FIELD_TECHNICAL || role === ROLES_LABEL.ADMIN) {
        dispatch(apiGetBatches(parseInt(communityId)));
      }
      if(role === ROLES_LABEL.BOARD || role === ROLES_LABEL.ADMIN) {
        dispatch(apiGetAccountEntries(parseInt(communityId)));
      }
    } else if (!communityId) {
      dispatch(resetSelectedAnnualPlan());
      dispatch(selectCommunity());
      dispatch(apiResetInterventionZones());
      dispatch(apiResetAccountEntries());
      dispatch(apiResetAnnualPlans());
      dispatch(apiResetCommoners());
      dispatch(apiResetBatches());
      dispatch(apiResetCommunityFiles());
    }
  }, [activedFilters, communities, communitiesInState, communityId, selectedCommunity]);

  useEffect(() => {
    if (interventionZones.length && interventionZoneId) {
      dispatch(selectInterventionZone(parseInt(interventionZoneId)));
    } else {
      dispatch(selectInterventionZone());
    }
  }, [interventionZoneId, interventionZones]);

  useEffect(() => {
    if (interventionZones.length && interventionTypes.length && interventionId && selectedCommunity.id && selectedInterventionZone) {
      dispatch(selectIntervention(parseInt(interventionId)));
    } else {
      dispatch(selectIntervention());
    }
  }, [interventionId, interventionTypes, selectedCommunity?.id, selectedInterventionZone]);

  return <div style={{position: 'absolute', width: '100%', height: '100%'}}>
    <DeleteFiltersDialog
      description={isDeleteFilterDialogOpen}
      isOpen={isDeleteFilterDialogOpen}
      onAccept={() => setIsDeleteFilterDialogOpen(undefined)}
    />
    <AdvancedFiltersView
      {...advancedFilterViewProps}
    />
    <CommunityMap
      {...mapProps}
    />
  </div>;
};

export default CommunityMapPage;
import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ReadOnlyData = ({label, value}) => {
  return <Box mt={1} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
    <Typography sx={{minWidth: '90px'}}>{label}</Typography>
    <Typography sx={{fontWeight: 900}}>{value}</Typography>
  </Box>;
};

ReadOnlyData.propTypes = {
  label: PropTypes.string.isRequired,
  value:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
};

export default ReadOnlyData;